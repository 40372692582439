<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="back()">返回</el-button> </p>
                <div class="h-b"><el-button type="primary" size="small" @click="addMemberInfo" v-right-code="'Memberinfo:Createserviceprovider'">新增</el-button> </div>
                <div class="h-b"><el-button type="text" size="small" @click="saveMemberInfo" v-right-code="'Memberinfo:Createserviceprovider'">保存</el-button> </div>
            </div>
        </div>
        <el-tabs class="done" type="border-card" v-model="activeName" @tab-click="tab1Click">
            <el-tab-pane label="基本信息" name="tabMemberInfo">
                <div class="form-list">
                    <el-form ref="_memberInfoForm" :model="dataSource" :rules="memberInfoCheckRule">
                        <el-form-item>
                            <div :hidden="!isDisabled">
                                <el-col :span="3" class="form-title"><span style="color:red;">*</span>服务商编号：</el-col>
                                <el-col :span="7">
                                    <el-form-item prop="MemberCode">
                                        <el-input v-model="dataSource.MemberCode" :maxlength="30" :minlength="4" :disabled="true" placeholder="服务商编号"></el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>服务商名称：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="MemberName">
                                    <el-input v-model="dataSource.MemberName" :maxlength="30" :minlength="4" placeholder="服务商名称"></el-input>
                                </el-form-item>
                            </el-col>
                            
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系人：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ContactPerson">
                                    <el-input v-model="dataSource.ContactPerson" :maxlength="30" :minlength="4" placeholder="联系人"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>联系电话：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ContactMobile">
                                    <el-input v-model="dataSource.ContactMobile" :maxlength="30" :minlength="4" placeholder="联系电话"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">
                                <span style="color:red;">*</span>地区：
                            </el-col>
                            <el-col :span="9" class="form-title">
                                <Distpicker :placeholders="dataSource"></Distpicker>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="MemberAddress">
                                    <el-input v-model="dataSource.MemberAddress" :maxlength="200" placeholder="详细地址"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>状态：</el-col>
                            <el-col :span="7">
                                <el-radio-group v-model="dataSource.MemberStatus">
                                    <el-radio label="1">启用</el-radio>
                                    <el-radio label="2">停用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">描述：</el-col>
                            <el-col :span="17">
                                <el-form-item prop="Memo">
                                    <el-input type="textarea" v-model="dataSource.Memo" :maxlength="500"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
            <el-tab-pane label="服务仓库" name="tabwarehouseInfo" v-if="isDisabled&&dataSource.ServiceWarehouseList!=null">
                <div class="form-list">
                    <fixed-list :dataSource="dataSource.ServiceWarehouseList" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
                        <p slot="elList">
                            <el-table ref="changeStockListTable" :data="dataSource.ServiceWarehouseList.Result||[]" border highlight-current-row>
                                <el-table-column v-for="(col,index,count)  in dataSource.ServiceWarehouseList.ColDefs.BodyFieldParams"
                                                    :key="index"
                                                    :prop="col.FieldName"
                                                    :label="col.DisplayName"
                                                    :render-header="bindFilter(queryParam,col)"
                                                    :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                                    :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                                    v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay))">
                                    <template slot-scope="scope">
                                        <span>  {{ scope.row[col.FieldName] }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </p>
                    </fixed-list>
                </div>
                            
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        data() {
            var checkMemberName = function (rule, value, callback) {
                if (!value) return callback(new Error('服务商名称不能为空'));
                else {
                    //console.log("datasouc:");
                    //console.log(this.dataSource);
                }
                callback();
            };
            var checkContactPerson = function (rule, value, callback) {
                if (!this.isDisabled) {
                    if (!value) return callback(new Error('联系人不能为空'));
                }
                callback();
            };
            var checkContactMobile = function (rule, value, callback) {
                var phoneReg = /^1[3-578]\d{9}$/;
                if (!this.isDisabled) {
                    if (!value) return callback(new Error('联系电话不能为空'));
                    else if (!phoneReg.test(value)) return callback(new Error('联系电话格式不正确'));
                }
                callback();
            };
            var checkMemberAddress = function (rule, value, callback) {
                if (!value) return callback(new Error('地址不能为空'));
                callback();
            };
            return {
                multipleSelection: [],
                isDisabled: false,
                activeName: 'tabMemberInfo',
                memberInfoCheckRule: {
                    MemberName: [{ validator: this.checkMemberName, trigger: 'blur' }],
                    ContactPerson: [{ validator: checkContactPerson, trigger: 'blur' }],
                    ContactMobile: [{ validator: checkContactMobile, trigger: 'blur' }],
                    MemberAddress: [{ validator: checkMemberAddress, trigger: 'blur' }]
                },
                queryParam: {
                    PageIndex: 1,
                    PageSize: 10,
                    Params: {
                        MemberId:this.dataSource.MemberId
                    }
                },
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            dataSource: {
                MemberName:""
            }
        },
        mounted() {
            this.Event.$on("clearEditMemberInfoForm", () => this.resetForm);
        },
        watch: {
            dataSource: {
                handler(curVal, oldVal) {
                    console.log(this.dataSource);
                    this.isDisabled = this.dataSource.MemberId!=null|| this.dataSource.MemberId!="0";
                },
                deep: true
            }
        },
        created: function () {
            this.Event.$on("clearForm", this.resetForm);
        },
        methods: {
            checkMemberName(rule, value, callback) {
                if (!value) return callback(new Error('服务商名称不能为空'));
                else {
                    this.$ajax.send("omsapi/memberinfo/checkserviceprovidernameexist", "post", this.dataSource, (data) => {
                        if (data.OperationDesc =="名称已存在") {
                            console.log(data);
                            return callback(new Error('服务商名称已存在'));
                        }
                    });
                }
                callback();
            },
            onPageChange() {
                var _this = this;
                var param=this.Utils.cloneObj (_this.queryParam);
                param.Params.MemberId = { FieldName: 'MemberId', FieldValue: _this.dataSource.MemberId };
                _this.$ajax.send("omsapi/memberinfo/searchservicewarehoustlist", "post", param, data => {
                    console.log("onPageChange");
                    if (_this.dataSource.ServiceWarehouseList != null) {
                        _this.dataSource.ServiceWarehouseList.Result = data.Result;
                    }
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource.ServiceWarehouseList = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource.ServiceWarehouseList = ds;

                });
            },
            tab1Click: function (tab, event) {
            },
            back(){
                this.$parent.checkSaveStatus();
            },
            addMemberInfo() {
                this.Event.$emit("onAddMemberInfo");
            },
            resetForm: function (memberId) {
                !this.isPassValidate && this.$refs['_memberInfoForm'].resetFields();//清空表单
            },
            saveMemberInfo() {
                var _this = this;
                if (!_this.dataSource.CityName || !_this.dataSource.CountyName || !_this.dataSource.ProvinceName) {
                    _this.Utils.messageBox("地区信息不全.", "error");
                    return false;
                }
                _this.$refs["_memberInfoForm"].validate((valid) => {
                    _this.isPassValidate = valid;
                    console.log("isDisabled：" + this.isDisabled + "，valid：" + valid);
                    if (valid) {
                        var routeName =(this.dataSource.MemberId==null|| this.dataSource.MemberId=="0") ? "createserviceprovider" : "updateserviceprovider";
                        this.$ajax.send("omsapi/memberinfo/" + routeName, "post", _this.dataSource, (data) => {
                            _this.$parent.syncDataSource();
                            _this.Event.$emit("reloadMemberInfoPageList", data.Result);
                            _this.Utils.messageBox("保存成功.", "success");
                        });
                    } else {
                        return false;
                    }
                });
            }
        },
        components: {
        }
    }

</script>
